<template>
  <page-content>
    <page-title heading="การออกใบประกาศหลังเรียนจบ">
      <template v-slot:right>
        <!-- <b-button variant="danger" @click="exportAll"
          >ดาวน์โหลดรายงานสรุปทั้งหมด</b-button
        > -->
        <!-- <download-excel
          class="btn btn-danger cursor-pointer"
          :fields="export_fields"
          :fetch="fetchExport"
        >
          <b-spinner v-if="isExporting" label="exporting"></b-spinner>
          <span v-else>ดาวน์โหลดรายงาน</span>
        </download-excel> -->
      </template>
    </page-title>

    <b-row>
      <b-col cols="12">
        <!-- <div class="d-flex flex-row flex-wrap">
          <div class="col-3 px-2">
            <label class="font-weight-bold ft-s-14" for="fiscalYearFrom"
              >เริ่มปีการศึกษา</label
            >

            <b-form-input
              id="fiscalYearFrom"
              v-model="fiscalYearFrom"
            ></b-form-input>
          </div>
          <b-col cols="3" class="px-2">
            <label class="font-weight-bold ft-s-14" for="termFrom"
              >ภาคเรียน</label
            >

            <v-select
              id="termFrom"
              v-model="termFrom"
              :options="terms"
            ></v-select>
          </b-col>

          <div class="col-3 px-2">
            <label class="font-weight-bold ft-s-14" for="fiscalYearTo"
              >ถึงปีการศึกษา</label
            >

            <b-form-input v-model="fiscalYearTo"></b-form-input>
          </div>
          <b-col cols="3" class="px-2">
            <label class="font-weight-bold ft-s-14" for="termTo"
              >ภาคเรียน</label
            >

            <v-select id="termTo" v-model="termTo" :options="terms"></v-select>
          </b-col>
        </div> -->
        <filter-fiscal-year-term v-model="formFiscalYearTerm">
        </filter-fiscal-year-term>
      </b-col>

      <b-col cols="12" class="mt-2">
        <filter-master
          v-model="formfilter"
          :m-headquarter-id="
            !authUser.canAccessAllHeadQuarters ? authUser.mHeadquarterId : null
          "
          :m-division-id="
            !authUser.canAccessAllDivisions ? authUser.mDivisionId : null
          "
          :m-station-id="
            !authUser.canAccessAllStations ? authUser.mStationId : null
          "
          :disabledInput="{
            headQuarter:
              !authUser.canAccessAllHeadQuarters &&
              authUser.mHeadquarterId !== null,
            division:
              !authUser.canAccessAllDivisions && authUser.mDivisionId !== null,
            station:
              !authUser.canAccessAllStations && authUser.mStationId !== null,
          }"
          :hiddenInput="{ school: true, schoolClass: true, room: true }"
        >
        </filter-master>

        <!-- <filter-master
          v-model="formfilter"
          :hiddenInput="{ school: true, schoolClass: true }"
        >
        </filter-master> -->
      </b-col>

      <b-col cols="12" class="mt-2">
        <div class="d-flex flex-row flex-wrap align-items-end">
          <b-col sm="8" md="6" class="px-2">
            <b-form-group class="mb-0">
              <b-input-group class="input-group-seamless">
                <b-form-input
                  placeholder="ค้นหารายชื่อครูตำรวจแดร์หรือโรงเรียน"
                  v-model="keyword"
                  @keyup.enter="onKeywordSearched"
                ></b-form-input>

                <b-input-group-text slot="prepend">
                  <font-awesome-icon class="mx-auto" icon="search" />
                </b-input-group-text>

                <b-input-group-text v-show="keyword.length" slot="append">
                  <a href="javascript:void(0)" @click="clearKeyword">
                    <font-awesome-icon class="mx-auto" icon="window-close" />
                  </a>
                </b-input-group-text>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col sm="4" md="2" class="px-2 mt-3 mt-sm-0">
            <b-button
              variant="primary"
              block
              size="sm"
              @click="onKeywordSearched"
              >ค้นหา</b-button
            >
          </b-col>
        </div>
      </b-col>
    </b-row>

    <teaching-dare-table
      v-if="enable"
      ref="table"
      class="mt-4"
      :fields="fields"
      :params="tableParams"
      action-label=""
      :show-action="false"
      :see-detail="true"
      :show-delete="false"
      @seeDetail="onSeeDetail"
      @delete="onDelete"
      :isReport="true"
    />

    <b-card v-else class="text-center mt-4">
      คลิ๊ก <b>"ค้นหา"</b> เพื่อเริ่มดาว์นโหลดข้อมูล
    </b-card>
  </page-content>
</template>

<script>
// import vSelect from "vue-select";
// import DownloadExcel from "vue-json-excel";
import PageContent from "../../components/layout/PageContent";
import PageTitle from "../../components/layout/PageTitle";
// import CreateOrUpdateUser from "../../components/modal/CreateOrUpdateUser";
import TeachingDareTable from "../../components/table/TeachingDare";
import FilterMaster from "../../components/form/FilterMaster";
import FilterFiscalYearTerm from "../../components/form/FilterFiscalYearTerm";
// import DeleteUser from "../../components/modal/DeleteUser";
import { Auth } from "../../models";
import { MPrefix } from "../../models";

const defaultValue = "ทั้งหมด";

export default {
  components: {
    // vSelect,
    PageContent,
    PageTitle,
    TeachingDareTable,
    FilterMaster,
    FilterFiscalYearTerm,
    // DownloadExcel,
  },

  data() {
    return {
      keyword: "",
      // terms: ["1", "2"],
      // fiscalYearFrom: "",
      // termFrom: "",
      // fiscalYearTo: "",
      // termTo: "",
      formfilter: {},
      deleteData: {},
      transactionId: null,
      isExporting: false,
      showModal: false,
      showModalDelete: false,
      export_fields: {
        หมายเลข: "number",
        ชั่วโมงการสอน: "total_time",
        จำนวนเงิน: "total_money",
        วันที่ขอ: "created_at",
      },
      formFiscalYearTerm: {
        fiscalYearFrom: defaultValue,
        termFrom: defaultValue,
        fiscalYearTo: defaultValue,
        termTo: defaultValue,
      },

      enable: false,
      tableParams: {},
    };
  },

  // watch: {
  //   formfilter: {
  //     deep: true,
  //     handler: "onFormFilterChanged",
  //   },
  // },

  computed: {
    authUser() {
      return Auth.user();
    },

    fields() {
      const fields = [
        // { key: "order", label: "ลำดับ" },
        { key: "station", label: "สถานี", sortable: true },
        { key: "school", label: "โรงเรียน", sortable: true },
        { key: "amphur", label: "อำเภอ", sortable: true },
        { key: "province", label: "จังหวัด", sortable: true },
        { key: "classRoom", label: "ห้องเรียน", sortable: true },
        { key: "yearTerm", label: "ปีการศึกษา", sortable: true },
        { key: "user_name", label: "ชื่อ-นามสกุล", sortable: true },
        {
          key: "seeDetail",
          label: "พิมพ์ใบประกาศทั้งหมด",
          class: "text-center",
        },
      ];

      return fields;
    },

    // tableParams() {
    //   const { formfilter } = this;

    //   return {
    //     ...formfilter,
    //   };
    // },
  },

  methods: {
    onKeywordSearched() {
      this.$set(this.formfilter, "keyword", this.keyword);

      const { formfilter } = this;

      this.enable = false;

      this.tableParams = {
        ...formfilter,
      };

      this.enable = true;
    },

    clearKeyword() {
      this.keyword = "";
      this.$delete(this.formfilter, "keyword");
    },

    // async fetchExport() {
    //   let promise;

    //   const data = [];

    //   this.isExporting = true;

    //   try {
    //     throw "eiei";
    //     // promise = await Transection.api().findAll({}, { save: false });
    //   } catch (error) {
    //     this.$toast.error(
    //       "ไม่สามารถดึงข้อมูลรายงานสรุปทั้งหมดได้ในขณะนี้ กรุณาลองใหม่ภายหลัง"
    //     );
    //   } finally {
    //     this.isExporting = false;
    //   }
    // },

    onCreateSuccess() {
      this.$refs.table.refresh();
    },

    onSeeDetail() {},

    onDelete(item) {
      this.deleteData = { ...item };
      this.showModalDelete = true;
    },

    onDeleteSuccess() {
      this.$refs.table.refresh();
    },
  },
  async mounted() {
    await MPrefix.api().findAll();
  },
};
</script>
